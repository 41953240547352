import { Show } from "solid-js";
import { Stack, Typography, Divider } from "@suid/material";
import { getText } from '../SmallComponents/Translate'

import { WFChip } from './SmallChips';
import { isExtraSmall } from "../../../utils/sizeUtil"

interface WFStatusComponentType {
    type: "customer" | "candidate"
}

const  WFStatusComponent = (props: WFStatusComponentType) => {
    const txtStatus = getText("smallcomponent", "status")
    const txtContract = getText("smallcomponent", "contractsignature")

    const comment = props?.type ==="customer" ? "Ida is standby until Michael returns his contract." :
                                                 "Contract received within one week due to vacation" 
    return (
        <>
            <Stack direction="column" alignItems="left" sx={{ px: 2, py: 1, display: 'flex' }} >
                <Typography variant="overline" gutterBottom>{txtStatus()} </Typography>
                <Typography variant="h6" sx={{ px: 1, display: 'block' }}>{txtContract()} </Typography>
                <Divider />
                <Typography variant="body2" color="contrastText" sx={{ px: 1, py: 1, display: 'block' }} >{comment} </Typography>
                <Show
                    when={isExtraSmall()}
                    fallback={
                        <>
                        <Divider  >
                            <Stack direction= "row" spacing={1} sx={{ py: 1, display: 'flex' }}>
                                <WFChip type={props.type} wfCompleted={true} category="interview" >Interview #1</WFChip>
                                <WFChip type={props.type} wfCompleted={true} category="interview">Interview #2</WFChip>
                                <WFChip type={props.type} category="proposal">Proposal</WFChip>
                                <WFChip type={props.type} wfCompleted={false} category="contract" >Contract</WFChip>
                            </Stack>
                        </Divider>
                        </>
                    }
                >
                   <>
                        <Stack direction="column" spacing={1} sx={{ py: 1, display: 'flex' }}>
                            <WFChip type={props.type} wfCompleted={true} category="interview" >Interview #1</WFChip>
                            <WFChip type={props.type} wfCompleted={true} category="interview" >Interview #2"</WFChip>
                            <WFChip type={props.type} category="proposal">Proposal</WFChip>
                            <WFChip type={props.type} wfCompleted={false} category="contract" >Contract</WFChip>
                        </Stack>
                        </>
                </Show>
            </Stack >
        </>
    )
}

export {  WFStatusComponent }